import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { deleteUser, getAllUser } from "../../api/api";
import { Table, Modal } from "antd";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/spinnerSlice";
import toast from "react-hot-toast";

const Users = () => {
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  //Modal show and hide state
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  //Fetch All Users
  const fetchAllUsers = async () => {
    try {
      dispatch(showLoading());
      const res = await getAllUser();
      dispatch(hideLoading());

      if (res.success) {
        setUsers(res.data);
      }
    } catch (err) {
      dispatch(hideLoading());
      console.log(err);
    }
  };

  //Delete user acout
  const deleteUserAccount = async (record) => {
    const UserIdtoDelete = { UserIdtoDelete: record._id };

    console.log("User to delete" + JSON.stringify(UserIdtoDelete));
    try {
      dispatch(showLoading());
      const res = await deleteUser(UserIdtoDelete);
      //window.location.href = "/admin/doctors";
      toast.success(res.message);

      //Refresh the page after updating user doctor status
      fetchAllUsers();

      dispatch(hideLoading());
      dispatch(hideModal);
    } catch (err) {
      dispatch(hideLoading());
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  // antd table column
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Doctor",
      dataIndex: "isDoctor",
      render: (text, record) => <span>{record.isDoctor ? "Yes" : "No"}</span>,
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="d-flex">
          <Modal
            title="Delete User"
            open={open}
            onOk={() => deleteUserAccount(record)}
            onCancel={hideModal}
            okText="Confirm"
            cancelText="Cancel"
          >
            <p>Are you sure you want to delete this User</p>
          </Modal>
          <button className="btn btn-danger" onClick={showModal}>
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="table-responsive">
        <h1 className="mb-4 text-center mt-2">Users List</h1>
        <Table
          dataSource={users}
          columns={columns}
          rowKey={(record) => record._id}
          scroll={{ x: "max-content" }}
        />
      </div>
    </Layout>
  );
};

export default Users;
