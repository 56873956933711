import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { changeAccountStatus, getAllDoctor } from "../../api/api";
import { Table, Modal } from "antd";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/spinnerSlice";
import toast from "react-hot-toast";

const Doctors = () => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };
  const [doctors, setDoctors] = useState([]);
  const dispatch = useDispatch();

  //Fetch all doctors req
  const fetchAllDoctors = async () => {
    try {
      dispatch(showLoading());
      const res = await getAllDoctor();
      dispatch(hideLoading());

      if (res.success) {
        setDoctors(res.data);
      }
    } catch (err) {
      dispatch(hideLoading());
      console.log(err);
    }
  };

  //Change Doctor account status
  const handleAccountStatus = async (record, status) => {
    try {
      dispatch(showLoading());
      const res = await changeAccountStatus(record._id, status);
      //window.location.href = "/admin/doctors";
      toast.success(res.message);

      //Refresh the page after updating user doctor status
      fetchAllDoctors();

      dispatch(hideLoading());
      dispatch(hideModal);
    } catch (err) {
      dispatch(hideLoading());
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchAllDoctors();
  }, []);

  //Antd Table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      render: (text, record) => <span>0{record.phoneNumber}</span>,
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Exp",
      dataIndex: "experience",
    },
    {
      title: "Fees",
      dataIndex: "feePerConsultation",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="d-flex">
          {record.status === "pending" || record.status === "rejected" ? (
            <>
              <Modal
                title="Approve Doctor"
                open={open}
                onOk={() => handleAccountStatus(record, "approved")}
                onCancel={hideModal}
                okText="Confirm"
                cancelText="Cancel"
              >
                <p>Are you sure you want to Approve this Doctor</p>
              </Modal>
              <button className="btn btn-success" onClick={showModal}>
                Approve
              </button>
            </>
          ) : (
            <>
              <Modal
                title="Reject Doctor"
                open={open}
                onOk={() => handleAccountStatus(record, "rejected")}
                onCancel={hideModal}
                okText="Confirm"
                cancelText="Cancel"
              >
                <p>Are you sure you want to Reject this Doctor</p>
              </Modal>
              <button className="btn btn-danger" onClick={showModal}>
                Reject
              </button>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="table-responsive">
        <h1>All Doctors</h1>
        <Table
          dataSource={doctors}
          columns={columns}
          rowKey={(record) => record._id}
          scroll={{ x: "max-content" }}
        />
      </div>
    </Layout>
  );
};

export default Doctors;
