import React, { useEffect, useState } from "react";
import { getAllApprovedDoctors, getUserInfo } from "../api/api";
import Layout from "../components/Layout";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../redux/spinnerSlice";
import { Row } from "antd";
import DoctorList from "../components/DoctorList";

const Homepage = () => {
  const [doctors, setDoctors] = useState([]);
  const dispatch = useDispatch();

  const removeObjectById = (array, id) => {
    return array.filter((item) => item.userId !== id);
  };

  //Get all approved doctors
  const fetchAllApprovedDoctors = async () => {
    try {
      dispatch(showLoading());
      //Filtering the data using is _id on current user

      //Get all approved doctors
      const response = await getAllApprovedDoctors();

      //Get current user details
      const userInfo = await getUserInfo();

      //Remove current doctor from doctors list
      const updatedData = removeObjectById(response.data, userInfo.data._id);

      setDoctors(updatedData);
      dispatch(hideLoading());
    } catch (err) {
      dispatch(hideLoading());
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllApprovedDoctors();
  }, []);
  return (
    <>
      <Layout>
        <h1 className="text-center mb-3">Doctors List</h1>
        <Row>
          {doctors &&
            doctors.map((doctor) => (
              <DoctorList key={doctor._id} doctor={doctor} />
            ))}
        </Row>
      </Layout>
    </>
  );
};

export default Homepage;
